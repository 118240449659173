import '../reports/printReport.css'
import { useRef, useState, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { getToken } from '../services/authorize'
import axios from 'axios'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'


const RegistrationReport = () => {

  const printRef = useRef()
  const printReport = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'ทะเบียนนักเรียนใหม่-2567'
  })

  const [registerData, setRegisterData] = useState('')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/registrationReport`,
    {
      headers: {
          authorization: `Bearer ${getToken()}`
      }
    })
    .then(response => {
      setRegisterData(response.data)
    })
    .catch(err => alert(err))
  }, [])

  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Mapping column names to desired names
    const columnMap = {
      id: 'เลขประจำตัวนักเรียน',
      idCard: 'เลขประจำตัวประชาชน',
      name: 'ชื่อ-สกุล',
      birthDate: 'ว.ด.ป. เกิด',
      regisClass: 'สมัครเรียนชั้น'
    }

    // Extracting only specific columns and renaming them
    const filteredData = registerData.map(({ st_idnumber, st_idcard, st_name, st_birthdate, regis_academicClass }) => ({
      [columnMap.id]: st_idnumber,
      [columnMap.idCard]: st_idcard,
      [columnMap.name]: st_name[0] + ' ' + st_name[1] + ' ' + st_name[2],
      [columnMap.birthDate]: st_birthdate && st_birthdate.length > 0 ? st_birthdate[0] + ' ' + st_birthdate[1] + ' ' + st_birthdate[2] : '',
      [columnMap.regisClass]: regis_academicClass
    }))

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { 'p1': ws }, SheetNames: ['p1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    const fileName = 'ทะเบียนนักเรียนใหม่2568' + fileExtension;
    saveAs(data, fileName);
  }

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
      <div className="container-fluid">
        <div className="row box-content rounded">
          {/* header of main content */}
          <h4 className="text-dark fw-bold">
            <i className="bi bi-file-person me-2"></i>
            รายงานทะเบียนนักเรียนใหม่
          </h4>
          <hr className="text-dark mt-1 mb-3" />
          {/* body of main content */}
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-2 me-1" onClick={printReport}>
              <i className="bi bi-printer me-2"></i>
              ปริ้น
            </button>
            <button className="btn btn-primary mb-2 me-1" onClick={exportToExcel}>
              <i className="bi bi-filetype-xlsx me-2"></i>
              บันทึกเป็น Excel
            </button>
          </div>
          <div ref={printRef} className="print-report pb-1 ps-3 pe-3">
            <div className="table-responsive">
              <table className="table table-bordered table-sm">
                <thead>
                  <tr style={{ border: "none" }}>
                    <th
                      colSpan={9}
                      className="text-center fs-5 pt-3"
                      style={{ border: "none" }}
                    >
                      ทะเบียนนักเรียนใหม่ 2568
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center" style={{ width: "80px" }}>
                      เลขประจำตัว
                    </th>
                    <th className="text-center">ชื่อ นร.</th>
                    <th className="text-center" style={{ width: "110px" }}>
                      วดป
                    </th>
                    <th className="text-center" style={{ width: "250px" }}>ที่อยู่</th>
                    <th className="text-center">บิดา</th>
                    <th className="text-center">มารดา</th>
                    <th className="text-center" style={{ width: "60px" }}>
                      จบชั้น
                    </th>
                    <th className="text-center" style={{ width: "60px" }}>
                      เข้าสามัญ
                    </th>
                    <th className="text-center">ร.ร. เดิม/จังหวัด</th>
                  </tr>
                </thead>
                <tbody>
                {registerData && registerData.length > 0 ? (
                registerData.map((data, index) => (
                  <tr key={data.st_id}>
                    <td className='text-center'>{data.st_idnumber}</td>
                    <td>
                      <span className='fw-bold'>ชื่อ</span> {data.st_name[0] + ' ' + data.st_name[1]}
                      <br />
                      <span className='fw-bold'>สกุล</span> {data.st_name[2]}
                      <br />
                      <span className='fw-bold'>13หลัก</span> {data.st_idcard}
                      <br />
                      <span className='fw-bold'>โทร </span>{data.st_parentPhone || data.st_fatherPhone}
                    </td>
                    <td className='text-center'>{data.st_birthdate ? data.st_birthdate[0] + ' ' + data.st_birthdate[1] + ' ' + data.st_birthdate[2] : ''}</td>
                    <td>
                      {data.st_current}
                      <br />
                      <span className='fw-bold'>ตำบล</span> {data.st_currentSubDistrict}
                      <br />
                      <span className='fw-bold'>อำเภอ</span> {data.st_currentDistrict}
                      <br />
                      <span className='fw-bold'>จังหวัด</span> {data.st_currentProvince}
                      <br />
                      <span className='fw-bold'>รหัส ปณ.</span> {data.st_zipcode}
                    </td>
                    <td>
                      <span className='fw-bold'>ชื่อ</span> {data.st_fatherName ? data.st_fatherName[0] + ' ' + data.st_fatherName[1] : null}
                      <br />
                      <span className='fw-bold'>สกุล</span> {data.st_fatherName ? data.st_fatherName[2] : null}
                      <br />
                      <span className='fw-bold'>13หลัก</span> {data.st_fatherIdCard}
                      <br />
                      <span className='fw-bold'>โทร</span> {data.st_fatherPhone}
                      <br />
                      <span className='fw-bold'>อาชีพ</span> {data.st_fatherJob}
                    </td>
                    <td>
                      <span className='fw-bold'>ชื่อ</span> {data.st_motherName ? data.st_motherName[0] + ' ' + data.st_motherName[1] : null}
                      <br />
                      <span className='fw-bold'>สกุล</span> {data.st_motherName ? data.st_motherName[2] : null}
                      <br />
                      <span className='fw-bold'>13หลัก</span> {data.st_motherIdCard}
                      <br />
                      <span className='fw-bold'>โทร</span> {data.st_motherPhone}
                      <br />
                      <span className='fw-bold'>อาชีพ</span> {data.st_motherJob}
                    </td>
                    <td className='text-center'>{data.st_prevAcademicClass}</td>
                    <td className='text-center'>{data.regis_academicClass}</td>
                    <td>
                      <span className='fw-bold'>โรงเรียน</span> {data.st_prevAcademicSchool}
                      <br />
                      <span className='fw-bold'>ตำบล</span> {data.st_prevAcademicSubDistrict}
                      <br />
                      <span className='fw-bold'>อำเภอ</span> {data.st_prevAcademicDistrict}
                      <br />
                      <span className='fw-bold'>จังหวัด</span> {data.st_prevAcademicProvince}
                    </td>
                  </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">Loading...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default RegistrationReport